import "./App.css";
import "./output.css";
import HeroSection from "pages/LandingPage/HeroSection";
import { Outlet, Route, Routes } from "react-router-dom";
import Layout from "layout/Layout";
import StartWithLabib from "pages/StartWithLabib/StartWithLabib";
import Chat from "pages/Chat/Chat";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import NotFound from "pages/NotFound/NotFound";
function App() {
  const generateSessionId = () => {
    const newSessionId = uuidv4();
    localStorage.setItem("session_id", newSessionId);
    return newSessionId;
  };
  const [session_id, setSessionId] = useState("");
  useEffect(() => {
    const storedSessionId = localStorage.getItem("session_id");

    if (storedSessionId) {
      setSessionId(storedSessionId);
    } else {
      const newSessionId = generateSessionId();
      setSessionId(newSessionId);
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("session_id");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="text-white " 
    >
      <Routes>
        <Route
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path="/" element={<HeroSection />} />
          <Route path="/start" element={<StartWithLabib />} />
          <Route path="/chat/:id" element={<Chat />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
