import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { _axios } from "interceptor/http-config";
import { useChat } from "hooks/chat/useChat";
import html2pdf from "html2pdf.js";
import heroLogo from "assets/icons/hero-section/hero-section-logo.svg";

export const useLogic = () => {
  const params = useParams();

  const { data: chatUser, refetch } = useChat();
  const { data, isLoading } = useQuery(
    ["products", `id-${params.id}`],
    async () => {
      return await _axios
        .get(`/user/get-pattern-input/${params.id}`)
        .then((res) => res.data?.pattern);
    },
    {}
  );
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [copiedChat, setCopiedChat] = useState(false);
  const [isChatButtonClicked, setChatButtonClicked] = useState(false);
  const [formData, setFormData] = useState({});
  const [assistantData, setAssistantData] = useState([]);
  const [copied, setCopied] = useState(false);
  const [isFavorite, setIsFavorite] = useState("");
  const [favoriteItems, setFavoriteItems] = useState([]);
  const [previousQuestion, setPreviousQuestion] = useState("");
  const [Loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(0);
  const [chat, setChat] = useState("");
  const [showChat, setShowChat] = useState(true);
  const [showFavorites, setShowFavorites] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);
  const [expandedMessages, setExpandedMessages] = useState([]);
  const [clickedContent, setClickedContent] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleReset = () => {
    if (document.querySelector("form") === "")
      return enqueueSnackbar("nothing to reset", {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    else document.querySelector("form").reset();
  };

  const handleCopyClick = () => {
    const content = assistantData.slice(-1)[0]?.content;

    if (content) {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(content)
          .then(() => {
            setCopied(true);
          })
          .catch((error) => {
            console.error("Error copying text:", error);
          });
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = content;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        setCopied(true);
      }
    }
  };
  const [selectedOption, setSelectedOption] = useState("");
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "drop_down") {
      setSelectedOption(value);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleCopyChat = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopiedChat(true);
          setIsFavorite(true);
          setTimeout(() => {
            setCopiedChat(false);
          }, 1500);
        })
        .catch((error) => {
          console.error("Error copying text:", error);
        });
    } else {
      // Fallback method for browsers that don't support clipboard API
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);

      setCopiedChat(true);
      setIsFavorite(true);
      setTimeout(() => {
        setCopiedChat(false);
      }, 1500);
    }
  };

  const hanldeclearOutput = () => {
    setAssistantData([]);
    setShowChat(false);
    setCounter(0);
    refetch();
  };
  // old template
  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   let commandMessage;

  //   switch (data?.title) {
  //     case "بيان المنتج":
  //       commandMessage = `Write a detailed product catalog in Arabic. Below are the ${Object.entries(
  //         formData
  //       )
  //         .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
  //         .join(
  //           ", "
  //         )}. Include ingredients decomposition and calories when applicable`;
  //       break;
  //     case "ميزات المنتج":
  //       commandMessage = `Create key feature and benefit bullet points for Amazon listings under the 'about this item' section in Arabic.
  //     Product name: [ [
  //       ${Object.entries(formData)
  //         .filter(([key]) => key === "product_name")
  //         .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
  //         .join(", ")}
  //     ] ]
  //     Product description: [ [
  //       ${Object.entries(formData)
  //         .filter(([key]) => key === "product_info")
  //         .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
  //         .join(", ")}
  //     ] ]
  //     Product Benefits: [ [
  //       ${Object.entries(formData)
  //         .filter(([key]) => key === "benefits")
  //         .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
  //         .join(", ")}
  //     ] ]`;
  //       break;
  //     case "وصف منتج أمازون (فقرة)":
  //       commandMessage = `As a digital marketer, write a detailed amazon product description in Arabic on [ [ ${Object.entries(
  //         formData
  //       )
  //         .filter(([key]) => key === "product_name")
  //         .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
  //         .join(", ")}] ]. \n Product features are: [ [  ${Object.entries(
  //         formData
  //       )
  //         .filter(([key]) => key === "benefits")
  //         .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
  //         .join(", ")}] ]`;
  //       break;
  //     case "عنوان المنتج":
  //       commandMessage = `As a digital marketer, create three amazon product titles in Arabic and display them as a list: [${Object.entries(
  //         formData
  //       )
  //         .filter(([key]) => key === "product_name")
  //         .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
  //         .join(", ")}] \n with the following key features: [${Object.entries(
  //         formData
  //       )
  //         .filter(([key]) => key === "benefits")
  //         .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
  //         .join(", ")}]`;
  //       break;
  //     case "المحرر":
  //       commandMessage = `As an arabic editor, edit the following text: [ [${Object.entries(
  //         formData
  //       )
  //         .filter(([key]) => key === "Content")
  //         .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
  //         .join(", ")}] ] `;
  //       break;
  //     case "المحتوى الإبداعي":
  //       commandMessage = `Write a creative and engaging blog post in Arabic on: [ [${Object.entries(
  //         formData
  //       )
  //         .filter(([key]) => key === "plot")
  //         .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
  //         .join(", ")}] ] `;
  //       break;
  //     default:
  //       commandMessage = "";
  //       break;
  //   }

  //   const message = {
  //     command: commandMessage,
  //     ...formData,
  //   };

  //   const formDataObject = new FormData();
  //   const session_id = localStorage.getItem("session_id");
  //   formDataObject.append("session_id", session_id);
  //   formDataObject.append("message", JSON.stringify(message));
  //   setLoading(true);

  //   _axios
  //     .post("/user/send-question", formDataObject)

  //     .then((data) => {
  //       if (Array.isArray(data?.data)) {
  //         const filteredData = data?.data?.filter(
  //           (item) => item.role === "assistant"
  //         );

  //         setAssistantData(filteredData);
  //         console.log("filteredData", filteredData);
  //       } else {
  //         console.error("Invalid data format");
  //       }
  //     })

  //     .catch((error) => {
  //       console.error(error);
  //     })
  //     .finally(() => setLoading(false));
  // };
  // new template
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let commandMessage;

    switch (data?.title) {
      case "المحرر":
        commandMessage = `Act as an editor. Answer only in Arabic. Analyze the following text and list sentences that need editing and describe why they need to edited:${Object.entries(
          formData
        )
          .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
          .join(", ")} `;
        break;
      case "تشكيل النص العربي":
        commandMessage = `Act as an arabic linguist with over 30 years of experience. Diactritize the following Arabic text:${Object.entries(
          formData
        )
          .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
          .join(", ")} `;
        break;
      case "النص الإبداعي":
        commandMessage = `Act as a novelist. Write in Arabic a creative story that is engaging, deeply reflective, introspective, and emotionally evocative about: [${Object.entries(
          formData
        )
          .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
          .join(", ")}]`;
        break;
      case "مطور الأفكار":
        commandMessage = `Act as a creative director. List 5 engaging and informative topics in Arabic to write about ${Object.entries(
          formData
        )
          .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
          .join(", ")}`;
        break;
      case "تحويل المقال إلى تغريدة":
        commandMessage = `Act as a digital journalist. Write a Twitter thread in Arabic about this article: ${Object.entries(
          formData
        )
          .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
          .join(", ")}`;
        break;
      case "تلخيص النص":
        commandMessage = `Write your answer in Arabic. Summarize the following article in a paragraph. Article: ${Object.entries(
          formData
        )
          .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
          .join(", ")}`;
        break;
      case "محرر العناوين":
        commandMessage = `Act as a creative director. Propose 5 titles in Arabic that are engaging based on this summary: ${Object.entries(
          formData
        )
          .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
          .join(", ")}`;
        break;
      case "مخطط تفصيلي للمحتوى":
        commandMessage = `Act as a content creator. Give me a detailed outline in Arabic to write about: ${Object.entries(
          formData
        )
          .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
          .join(", ")}`;
        break;
      case "محرر التأثير اللغوي":
        commandMessage = `Act as an editor. Rewrite the following article in Arabic with ${selectedOption}, Input Values: ${Object.entries(
          formData
        )
          .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
          .join(", ")}`;
        break;
      case "مطور الأسئلة للحوارات والمقابلات":
        commandMessage = `What are some impactful and insightful questions to ask during a podcast, article, or TV interview that would captivate and benefit my audience? The topic of the interview [${Object.entries(
          formData
        )
          .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
          .join(", ")}]. The audience is [ ${Object.entries(formData)
          .filter(([key]) => key === "audience")
          .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
          .join(", ")} ]. Keep your response in Arabic.`;
        break;
      default:
        commandMessage = "";
        break;
    }

    // const message = {
    //   commandMessage,
    //   ...formData,
    // };

    const formDataObject = new FormData();
    const session_id = localStorage.getItem("session_id");
    formDataObject.append("session_id", session_id);
    formDataObject.append("message", JSON.stringify(commandMessage));
    setLoading(true);

    _axios
      .post("/user/send-question", formDataObject)

      .then((data) => {
        console.log(selectedOption);
        if (Array.isArray(data?.data)) {
          const filteredData = data?.data?.filter(
            (item) => item.role === "assistant"
          );
          setAssistantData(filteredData);
          console.log("filteredData", filteredData);
        } else {
          console.error("Invalid data format");
        }
      })

      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };
  const sendQuestionMutation = useMutation((formData) =>
    _axios.post("/user/send-question", formData).then(refetch())
  );
  const hanldeChat = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    const session_id = localStorage.getItem("session_id");
    formData.append("session_id", session_id);
    formData.append("message", chat);
    setChat("");
    setLoading(true);
    setCounter((prevCounter) => prevCounter + 1);
    setShowChat(true);
    setPreviousQuestion(chat);
    refetch();

    try {
      await sendQuestionMutation.mutateAsync(formData); // Make the mutation request

      if (isChatButtonClicked) {
        refetch();
      }
    } catch (error) {
    } finally {
      setLoading(false); // Set loading state to false after the request is completed (success or error)
    }
  };

  const handleFavorite = (question, answer) => {
    const formData = new FormData();
    formData.append("question", question);
    formData.append("answer", answer);
    const isItemFavorited = favoriteItems.some(
      (item) => item.answer === answer
    );

    if (isItemFavorited) {
      // Remove the item from favorites
      setFavoriteItems((prevItems) =>
        prevItems.filter((item) => item.answer !== answer)
      );
      setShowFavorites(false);
    } else {
      // Add the item to favorites
      const newItem = { question, answer };
      setFavoriteItems((prevItems) => [...prevItems, newItem]);
      setShowFavorites(true);
    }
    _axios
      .post("/user/favorite", formData)
      .then((data) => {
        setIsFavorite(data.isFavorite);
        // setFavoriteItems((prevItems) => [...prevItems, { question, answer }]);
        setFavoriteItems([{ question, answer }]);

        setShowFavorites(true);
      })
      .catch((error) => {
        console.error("Error favoriting:", error);
      });
  };
  const handleRemoveAllFavorites = () => {
    setFavoriteItems([]);
    setShowFavorites(false);
  };
  const handleDownloadFavorites = () => {
    const favoriteText = favoriteItems.map((item) => item.answer).join("\n");
    const blob = new Blob([favoriteText], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "favorites.txt";
    link.click();
  };
  const handleDownloadFavoritesPdf = () => {
    const content = favoriteItems
      .map((item) => `<p>${item.answer}</p>`)
      .join("");

    const modifiedContent = `<html>
  <head>
    <style>
      p {
        color: #333;
        margin-bottom: 10px;
        direction: rtl;
        padding: 20px;
        white-space: pre-line;
      }
      img {
        padding: 10px;
      }
      .pdfLogo {
        display: flex;
        width: 100%;
      }
    </style>
  </head>
  <body>
    <div class="pdfLogo">
      <img src="${heroLogo}" alt="" />
    </div>
    ${content}
    <br />
  </body>
</html>`;

    const element = document.createElement("div");
    element.innerHTML = modifiedContent;

    html2pdf().from(element).save("favorites.pdf");
  };

  const handleReadMore = () => {
    setShowFullContent(true);
  };

  const handleReadMoreChat = (index, content) => {
    setExpandedMessages((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = true;
      return updatedState;
    });
    setClickedContent(content);
  };

  useEffect(() => {
    if (isChatButtonClicked) {
      refetch();
    }
  }, [isChatButtonClicked, refetch]);

  const handleTextareaKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };
  const handleTextareaKeyDownChat = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      hanldeChat(event);
    }
  };
  return {
    handleSubmit,
    navigate,
    hanldeclearOutput,
    handleInputChange,
    assistantData,
    handleReset,
    data,
    isLoading,
    handleRemoveAllFavorites,
    chatUser,
    handleDownloadFavoritesPdf,
    handleDownloadFavorites,
    handleFavorite,
    showFavorites,
    isFavorite,
    previousQuestion,
    hanldeChat,
    counter,
    showChat,
    Loading,
    handleCopyChat,
    handleCopyClick,
    copied,
    isChatButtonClicked,
    setChatButtonClicked,
    copiedChat,
    chat,
    setChat,
    favoriteItems,
    showFullContent,
    setShowFullContent,
    handleReadMore,
    expandedMessages,
    setExpandedMessages,
    clickedContent,
    setClickedContent,
    handleReadMoreChat,
    handleTextareaKeyDown,
    handleTextareaKeyDownChat,
  };
};
