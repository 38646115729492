import React, { useEffect, useState } from "react";
import Loader from "components/shared/Loader";
import chatIcon from "assets/icons/chatIcon.svg";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { Avatar, Tooltip } from "@mui/material";
import copyIcon from "assets/icons/hero-section/copy.svg";
import labibAvatar from "assets/icons/labibAvatar.svg";
import sendIcon from "assets/icons/sendIcon.svg";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { motion, useAnimation } from "framer-motion";
import { useLogic } from "./hooks/useLogic";
import arrow from "assets/icons/Arrow.svg";
// import { motion } from "framer-motion";
const Chat = () => {
  const {
    handleSubmit,
    navigate,
    hanldeclearOutput,
    handleInputChange,
    assistantData,
    handleReset,
    data,
    isLoading,
    handleRemoveAllFavorites,
    chatUser,
    handleDownloadFavoritesPdf,
    handleDownloadFavorites,
    handleFavorite,
    showFavorites,
    isFavorite,
    previousQuestion,
    hanldeChat,
    counter,
    showChat,
    Loading,
    handleCopyChat,
    handleCopyClick,
    copied,
    isChatButtonClicked,
    setChatButtonClicked,
    chat,
    setChat,
    favoriteItems,
    showFullContent,
    handleReadMore,
    expandedMessages,
    clickedContent,
    handleReadMoreChat,
    copiedChat,
    handleTextareaKeyDown,
    handleTextareaKeyDownChat,
  } = useLogic();
  const [close, setClose] = useState(false);

  return (
    <div className="pt-20 pb-80">
      <section className="">
        <div className="flex justify-end mb-10">
          <div
            className="p-3 rotate-180 border border-white rounded-full cursor-pointer border-1"
            onClick={() => navigate("/start")}
          >
            <ArrowBackIosRoundedIcon />
          </div>
        </div>
        <div className="flex flex-col-reverse w-full gap-5 duration-1000 ease-linear md:flex md:flex-row">
          <div
            className={`${
              close
                ? "flex-1 ease-linear	duration-1000 "
                : "flex-[0.6] ease-linear	duration-1000"
            }`}
          >
            <div className="bg-black/40  flex justify-between p-10 h-320 items-center rounded-[10px]">
              <button onClick={hanldeclearOutput}>
                <p className="text-white">مسح</p>
              </button>
              <p className="bg-[#0E1939] px-5 py-2 rounded tracking-widest">
                {" "}
                {counter} : النتيجة
              </p>
            </div>
            <div className="flex flex-col float-right">
              <>
                <div
                  className={`flex flex-col gap-10 ${
                    close && "md:w-[90vw]  ease-linear	duration-1000"
                  } w-[95vw] md:w-[60vw] `}
                >
                  {!isChatButtonClicked && (
                    <div
                      className={`bg-black/40   mt-20 rounded-[10px] float-right p-8 md:p-20 relative flex flex-col `}
                    >
                      <p
                        className="flex justify-center float-right text-right "
                        dir="rtl"
                      >
                        {Loading ? (
                          <Loader />
                        ) : showFullContent ? (
                          assistantData.slice(-1)[0]?.content
                        ) : (
                          <>
                            <div
                              className={`hidden  w-full md:flex md:flex-col `}
                              style={{ whiteSpace: "pre-line" }}
                            >
                              {assistantData
                                .slice(-1)[0]
                                ?.content?.slice(0, 2000) ?? "لا توجد رسائل"}
                              {assistantData.slice(-1)[0]?.content?.length >
                                2000 && (
                                <button
                                  onClick={handleReadMore}
                                  className="text-right text-blue-500 "
                                >
                                  ...رؤية المزيد
                                </button>
                              )}
                            </div>
                            <div
                              className="flex flex-col w-full md:hidden"
                              style={{ whiteSpace: "pre-line" }}
                            >
                              {assistantData
                                .slice(-1)[0]
                                ?.content?.slice(0, 100) ?? "لا توجد رسائل"}
                              {assistantData.slice(-1)[0]?.content?.length >
                                100 && (
                                <button
                                  onClick={handleReadMore}
                                  className="text-right text-blue-500 "
                                >
                                  ...رؤية المزيد
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </p>
                      {assistantData.slice(-1)[0]?.content?.slice(0, 100) && (
                        <div>
                          <button onClick={handleCopyClick} className="pt-10">
                            <Tooltip title={copied ? "Copied" : "Copy"}>
                              <img src={copyIcon} alt="" />
                            </Tooltip>
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {isChatButtonClicked && (
                    <div className="rounded-[10px]  ">
                      <div>
                        {showChat &&
                          chatUser?.chat?.map((item, index) => (
                            <div key={index}>
                              <p
                                dir="rtl"
                                style={{ whiteSpace: "pre-line" }}
                                className={`${
                                  item.role === "user"
                                    ? "bg-[#76E7B4] text-[#000061] flex float-right w-1/2 rounded-[20px] my-10 "
                                    : "bg-black/40 w-1/2 border border-1 border-black rounded-[20px] "
                                } rounded flex-col  mx-10 mt-10  p-10 relative hidden md:flex   `}
                              >
                                {expandedMessages[index] ? (
                                  item.content ?? <Loader />
                                ) : (
                                  <>
                                    {item.content?.slice(0, 400)}
                                    {item.content?.length > 400 && (
                                      <button
                                        onClick={() =>
                                          handleReadMoreChat(
                                            index,
                                            item.content
                                          )
                                        }
                                        className="text-right text-blue-500"
                                      >
                                        ...رؤية المزيد
                                      </button>
                                    )}
                                  </>
                                )}
                                <div className="flex flex-row-reverse float-left gap-5 p-2 pt-10 ">
                                  <button
                                    onClick={() => handleCopyChat(item.content)}
                                  >
                                    <Tooltip
                                      title={copiedChat ? "Copied" : "Copy"}
                                    >
                                      <img src={copyIcon} alt="" />
                                    </Tooltip>
                                  </button>
                                  {item.role === "assistant" && (
                                    <button
                                      onClick={() =>
                                        handleFavorite(
                                          previousQuestion,
                                          item.content
                                        )
                                      }
                                    >
                                      {isFavorite &&
                                      previousQuestion === item.content ? (
                                        <StarRateRoundedIcon />
                                      ) : (
                                        <StarBorderRoundedIcon />
                                      )}
                                    </button>
                                  )}
                                  {item.content === clickedContent && (
                                    <button onClick={() => item.content}>
                                      {/* Button Text */}
                                    </button>
                                  )}
                                </div>
                              </p>
                              <p
                                dir="rtl"
                                style={{ whiteSpace: "pre-line" }}
                                className={`${
                                  item.role === "user"
                                    ? "bg-[#76E7B4] text-[#000061] flex float-right w-1/2 rounded-[20px] my-10"
                                    : "bg-black/40 w-1/2 border border-1 border-black rounded-[20px] ml-10"
                                } rounded flex flex-col mt-5 p-10 relative   md:hidden `}
                              >
                                {expandedMessages[index] ? (
                                  item.content ?? <Loader />
                                ) : (
                                  <>
                                    {item.content?.slice(0, 100)}
                                    {item.content?.length > 100 && (
                                      <button
                                        onClick={() =>
                                          handleReadMoreChat(
                                            index,
                                            item.content
                                          )
                                        }
                                        className="text-right text-blue-500"
                                      >
                                        ...رؤية المزيد
                                      </button>
                                    )}
                                  </>
                                )}
                                <div className="absolute bottom-0 left-0 flex gap-5 p-2 pt-10 ">
                                  <button
                                    onClick={() => handleCopyChat(item.content)}
                                  >
                                    <Tooltip
                                      title={copiedChat ? "Copied" : "Copy"}
                                    >
                                      <img src={copyIcon} alt="" />
                                    </Tooltip>
                                  </button>
                                  {item.role === "assistant" && (
                                    <button
                                      onClick={() =>
                                        handleFavorite(
                                          previousQuestion,
                                          item.content
                                        )
                                      }
                                    >
                                      {isFavorite &&
                                      previousQuestion === item.content ? (
                                        <StarRateRoundedIcon />
                                      ) : (
                                        <StarBorderRoundedIcon />
                                      )}
                                    </button>
                                  )}
                                  {item.content === clickedContent && (
                                    <button onClick={() => item.content}>
                                      {/* Button Text */}
                                    </button>
                                  )}
                                </div>
                              </p>
                              {item.role === "assistant" && (
                                <div className="flex items-end">
                                  <Avatar
                                    alt="labib"
                                    src={labibAvatar}
                                    className="w-2 h-2 p-2 abs  bg-[#0A0D69]"
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                  {!isChatButtonClicked &&
                    assistantData.slice(-1)[0]?.content && (
                      <button
                        onClick={() => setChatButtonClicked(true)}
                        className="font-bold flex gap-4 bg-gradient-to-r from-[#05A5A9] to-[#76E7B4]  rounded-[10px] w-40 p-3 ml-10 md:ml-0 text-black"
                      >
                        <img src={chatIcon} alt="" />
                        <h1 className="">دردشة لبيب</h1>
                      </button>
                    )}
                  {isChatButtonClicked && (
                    <form
                      onSubmit={hanldeChat}
                      className="bg-black/40 flex flex-row-reverse items-center border border-1 border-[#0AB0A9] rounded-lg px-4 py-2 w-full "
                    >
                      <textarea
                        className="bg-transparent rounded-[10px] w-full h-20 flex-grow resize-none focus:ring-0"
                        name="question"
                        value={chat}
                        onChange={(event) => setChat(event.target.value)}
                        onKeyDown={handleTextareaKeyDownChat}
                        dir="rtl"
                        placeholder="اكتب ما الذي تريد ارساله"
                        required
                      />
                      <div className="flex items-center">
                        <button
                          className="flex items-center justify-center px-4 py-2 ml-4 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:pointer-events-none"
                          type="submit"
                          disabled={Loading}
                        >
                          {Loading ? (
                            "جاري التحميل"
                          ) : (
                            <img src={sendIcon} alt="" className="" />
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </>
            </div>
          </div>
          <motion.div
            initial={{ width: 0, y: -500 }}
            animate={{ width: "100%", y: 0 }}
            transition={{ duration: 1 }}
            className="h-1/1  bg-[#89FFFD] md:w-[0.1] md:flex-[0.001] 2xl:w-[0.5] 2xl:flex-[0.0001]"
          ></motion.div>
          <img
            src={arrow}
            alt=""
            onClick={() => setClose(!close)}
            className={`${
              close
                ? "rotate-180 transition	ease-linear	duration-1000"
                : "rotate-0 transition	ease-linear	duration-1000"
            } hidden md:block`}
          />
          {!close && (
            <div
              className={`${
                !close
                  ? "flex-[0.4] transition  ease-linear	duration-1000 delay-1000  "
                  : "flex-0 transition  ease-linear	duration-1000 delay-1000 w-full "
              }`}
              style={{
                transition: `${!close && "5.0s"}`,
                duration: `${!close && "4000s"}`,
              }}
            >
              <div className="flex flex-col items-end text-end gap-7 ">
                <div className="bg-gradient-to-r from-[#00FF9E] to-[#0061FF] w-full   rounded-[10px] p-3 text-end  flex flex-row-reverse gap-10">
                  <div className="flex items-center w-1/6 sm:w-1/1 2xl:w-1/12 ">
                    <img
                      src={data?.icon}
                      alt=""
                      className="w-1/1 h-1/1 sm:w-1/1 sm:h-1/1"
                    />
                  </div>
                  <div className="flex flex-col gap-5 ">
                    <h1 className="text-xl 2xl:text-2xl">
                      {data?.title ?? "null"}
                    </h1>
                    <p className="md:text-xl">
                      {/* .اكتب قصصًا إبداعية لإشراك القراء */}
                      {data?.description}
                    </p>
                  </div>
                </div>
                {isChatButtonClicked && !showFavorites && (
                  <p
                    className="bg-black/40 w-1/1 border border-1 border-black float-right rounded-[20px] p-10 text-right"
                    style={{ whiteSpace: "pre-line" }}
                    dir="rtl"
                  >
                    {assistantData.slice(-1)[0]?.content}
                  </p>
                )}

                {showFavorites && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 2 }}
                    id="favorite-text"
                    className="bg-black/40 w-1/1 border border-1 border-black float-right rounded-[20px] p-10 text-right"
                    style={{ whiteSpace: "pre-line" }}
                    dir="rtl"
                  >
                    {favoriteItems.map((item, index) => (
                      <div key={index}>
                        <p>{item.answer}</p>

                        <div className="flex flex-row-reverse float-left gap-2 mt-5">
                          <button onClick={() => handleCopyChat(item.answer)}>
                            <Tooltip title={copiedChat ? "Copied" : "Copy"}>
                              <img src={copyIcon} alt="" />
                            </Tooltip>
                          </button>
                          <button
                            onClick={() =>
                              handleRemoveAllFavorites(item.answer)
                            }
                          >
                            {isFavorite && item.answer === item.content ? (
                              <StarRateRoundedIcon sx={{ color: "gold" }} />
                            ) : (
                              <StarRateRoundedIcon sx={{ color: "gold" }} />
                            )}
                          </button>
                          <TextFieldsIcon
                            onClick={handleDownloadFavorites}
                            className="cursor-pointer"
                          />
                          <PictureAsPdfIcon
                            onClick={handleDownloadFavoritesPdf}
                          />
                        </div>
                      </div>
                    ))}
                  </motion.div>
                )}

                <div className="flex items-center justify-center w-full">
                  {isLoading && <Loader />}
                </div>
                {!isChatButtonClicked && (
                  <form
                    onSubmit={handleSubmit}
                    className="flex flex-col w-full gap-10"
                  >
                    {data?.inputs?.slice(-2).map((item) => (
                      <div key={item.id} className="flex flex-col gap-2">
                        {item.type === "drop_down" && (
                          <>
                            <h1 className=""> نبرة الخطاب</h1>
                            <select
                              name="drop_down"
                              onChange={handleInputChange}
                              dir="rtl"
                              className="rounded-[10px] bg-transparent border border-1 border-[#0AB0A9] rounded-b-none w-full p-5 px-10"
                              required
                            >
                              <option value="" disabled selected hidden>
                                اختر
                              </option>
                              {data?.inputs?.slice(0, -1).map((option) => (
                                <option
                                  key={option.id}
                                  value={option.value}
                                  className="rounded-[10px] bg-primary-color border border-1 border-[#0AB0A9] w-full p-5"
                                >
                                  <div className="border border-1 border-[#0AB0A9]">
                                    {option.type === "drop_down" ? (
                                      option.title
                                    ) : (
                                      <div
                                        disabled
                                        hidden
                                        className="hidden none"
                                      >
                                        null
                                      </div>
                                    )}
                                  </div>
                                </option>
                              ))}
                            </select>
                          </>
                        )}

                        {item.type !== "drop_down" && (
                          <>
                            <h1>{item.title}</h1>
                            <textarea
                              type={item.type}
                              name={item.name}
                              onChange={handleInputChange}
                              onKeyDown={handleTextareaKeyDown}
                              className="rounded-[10px] bg-transparent border border-1 border-[#0AB0A9] w-full h-44 p-5"
                              dir="rtl"
                              required
                              max={item.max_tokens}
                            />
                          </>
                        )}
                      </div>
                    ))}

                    <div className="flex justify-between">
                      <button
                        className="text-black font-bold bg-gradient-to-r from-[#00FF9E] to-[#0061FF] w-1/4 rounded p-3"
                        type="submit"
                        disabled={Loading}
                      >
                        <h1 className="text-xl">
                          {Loading ? "جاري التحميل" : "إنشاء"}
                        </h1>
                      </button>
                      <button
                        type="button"
                        onClick={handleReset}
                        disabled={false}
                        className="font-bold  bg-gradient-to-r from-[#00FF9E] to-[#0061FF] w-1/4 rounded p-3 text-black"
                      >
                        <h1 className="text-xl">حذف المدخلات</h1>
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Chat;
