import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useProducts } from "hooks/products/useProducts";
import Loader from "components/shared/Loader";
import { v4 as uuidv4 } from "uuid";

const StartWithLabib = () => {
  const { data, isLoading } = useProducts();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleView = (id) => {
    generateSessionId(id);
    navigate(`/chat/${id}`);
  };

  const generateSessionId = (id) => {
    const newSessionId = uuidv4();
    localStorage.setItem("session_id", newSessionId);
    return newSessionId;
  };

  return (
    <>
      <div className="">
        {isLoading && (
          <div className="flex items-center justify-center h-screen ">
            <Loader />
          </div>
        )}
      </div>
      <div className="flex items-center py-20 ">
        <div
          className="sm:grid sm:grid-cols-2 grid items-center justify-center w-full grid-cols-1 gap-5 sm:gap-5 mx-10 md:grid md:grid-cols-2 lg:grid lg:grid-cols-2 2xl:grid 2xl:grid-cols-3 place-items-center  lg:gap-10 2xl:gap-10 md:mx-[1px] lg:mx-[400px] 2xl:mx-[300px] "
          dir="rtl"
        >
          {!!data &&
            data?.patterns?.map((item, index) => (
              <div
                key={index}
                onClick={() => handleView(item.id)}
                className="bg-[#0B0B1261] border border-1 text-center border-[#00FF9E] rounded-[10px] grid grid-cols-1 sm:w-72 sm:h-60 w-60  cursor-pointer  p-14 sm:gap-5 place-items-center gap-5 2xl:gap-10"
              >
                <img
                  src={item.icon}
                  alt="labeb"
                  className="h-10 md:h-10 2xl:w-14 2xl:h-14"
                />
                <h1 className="md:text-xl ">{item.title}</h1>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default StartWithLabib;
