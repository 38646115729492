import React from "react";
import heroLogo from "../../assets/icons/hero-section/hero-section-logo.svg";
const Loader = () => {
  return (
    // <div>
    //   <lottie-player
    //     src="https://assets4.lottiefiles.com/packages/lf20_usmfx6bp.json"
    //     background="transparent"
    //     speed="1"
    //     style={{ width: " 300px", height: "300px" }}
    //     loop
    //     // controls
    //     autoplay
    //   ></lottie-player>
    // </div>
    <div className="image-container flex items-center  justify-center ">
      <img className="fade-animation w-10 h-10 md:h-full md:w-full"  src={heroLogo} alt="labeb" />
    </div>
  );
};

export default Loader;
