import React from "react";

const NotFound = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center text-3xl md:text-5xl gap-10">
      <h1>404</h1>
      <p>Sorry Missio Labib 🤭</p>
      <button className="font-bold flex gap-4 bg-gradient-to-r from-[#05A5A9] to-[#76E7B4]  rounded-[10px] text-xl  p-3 ">
        <a href="/">Back To Home</a>
      </button>
    </div>
  );
};

export default NotFound;
