import React, { useEffect } from "react";
import Header from "layout/header/Header";
import { useLocation, useParams } from "react-router-dom";
// import Footer from "./footer/Footer";

const Layout = ({ children }) => {
  const location = useLocation();
  const home = location.pathname === "/";
  const start = location.pathname === "/start";
  const params = useParams();
  const chat = location.pathname === `/chat/${params.id}`;

  useEffect(() => {}, [home, start]);

  return (
    <div>
      {(home || start || chat) && <Header />}
      {children}
      {/* {home && <Footer />} */}
    </div>
  );
};

export default Layout;
