import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo2.png";

import { NavLink, useLocation, useParams } from "react-router-dom";
import { _axios } from "interceptor/http-config";
import { motion } from "framer-motion";
// import { useProducts } from "hooks/products/useProducts";
// import bottomLine from "assets/Asset 5.svg";
// import styled, { keyframes } from "styled-components";
// const drawPath = keyframes`
//   0% {
//     stroke-dasharray: 0 100;

//   }

//   100% {
//     stroke-dasharray: 400 0 200;

//   }
// `;

// const StyledSVG = styled.svg`
//   width: 78%;
//   height: 400px;
//   position: absolute;
//   z-index: -10;

//   @media (max-width: 1200px) {
//     width: 100%;
//     z-index: -10;
//   }
// `;

// const AnimatedPath = styled.path`
//   fill: none;
//   stroke: #00e1b1;
//   stroke-linecap: round;
//   stroke-linejoin: round;
//   stroke-width: 2px;
//   stroke-dasharray: 0 400;
//   animation: ${drawPath} 4s linear infinite;
//   animation-delay: 2s;

//   @media (max-width: 1200px) {
//     width: 10%;
//   }
// `;
const HeroSection = () => {
  // const [copied, setCopied] = useState(false);
  // const textToCopy =
  //   "الحصول على لمحة عن المشهد المعقد لتطوير الذكاء الاصطناعي المسؤول";

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const hanldeEmail = () => {
    const formDataEmail = new FormData();
    formDataEmail.append("email", formData.email);

    _axios.post("/user/news-letter", formDataEmail);
  };
  const params = useParams();
  const location = useLocation();
  const [justifycenter, setJustifyCenter] = useState("justify-center");
  const landing = location.pathname === "/";
  const start = location.pathname === "/start";
  const chat = location.pathname === `/chat/${params.id}`;
  useEffect(() => {
    if (start || chat) {
      setJustifyCenter("justify-center");
    } else {
      setJustifyCenter("justify-start");
    }
  }, [start, chat]);
  const sentence = "لبيب";

  const wordVariants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
  };
  const sentence2 =
    "الذكاء الاصطناعي التوليدي متعدد الوسائط لتطوير ودعم المحتوى العربي";

  const wordVariants2 = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
  };
  // const handleCopyClick = () => {
  //   navigator.clipboard
  //     .writeText(textToCopy)
  //     .then(() => {
  //       setCopied(true);
  //       setTimeout(() => setCopied(false), 2000);
  //     })
  //     .catch((error) => {
  //       console.error("Failed to copy text:", error);
  //     });
  // };

  // const paraChange = [
  //   {
  //     img: checkIcon,
  //     title: "المحتوى الإبداعي",
  //     body: "قم بتحسين المحتوى عن طريق إعادة كتابته ليكون أكثر وضوحًا",
  //   },
  //   {
  //     img: infoIcon,
  //     title: "بيان المنتج",
  //     body: "إنشاء ميزة رئيسية ونقاط فائدة لقوائم امازون",
  //   },
  //   {
  //     img: saveIcon,
  //     title: "عنوان المنتج",
  //     body: "إنشاء عنوان إعلان منتج مقنع لقوائم أمازون ",
  //   },
  // ];
  // const { data } = useProducts();
  // const imageRef = useRef(null);

  // useEffect(() => {
  //   const imageElement = imageRef.current;

  //   const handleImageAnimationEnd = () => {
  //     // Start the SVG animation when the image animation is finished
  //     imageElement.removeEventListener("animationend", handleImageAnimationEnd);
  //     startSvgAnimation();
  //   };

  //   // Listen for the image animation end event
  //   imageElement.addEventListener("animationend", handleImageAnimationEnd);

  //   return () => {
  //     // Clean up the event listener
  //     imageElement.removeEventListener("animationend", handleImageAnimationEnd);
  //   };
  // }, []);

  // const startSvgAnimation = () => {};
  return (
    <div className="">
      <div className="flex flex-col gap-10 bg-no-repeat bg-cover md:bg-contain bg-blend-multiply bg-hero-section drop-shadow-4xl ">
        <div
          className={`flex ${justifycenter} mx-5 ${
            landing ? "flex-row-reverse  justify-between items-center  " : ""
          }`}
        >
          <NavLink to="/" className={`flex ${justifycenter} items-center`}>
            <img
              src={logo}
              alt="labib"
              className={`${
                start || chat
                  ? "w-1/1 h-1/2 md:w-80 md:h-full pt-10 "
                  : "md:w-80 h-1/2 sm:h-full p-2"
              }`}
            />
          </NavLink>
        </div>
        <div className="flex flex-col-reverse items-center justify-between gap-5 md:px-10 md:pt-5 2xl:mt-10">
          {/* <div className="w-1/4 ">
            <NavLink to="/start">
              <div className="border border-2 border-gray-200 rounded-[10px] sm:text-xl   xl:text-2xl  w-32 h-full p-3 sm:h-full sm:w-full sm:py-[3%]  text-center ">
                <button>إبدأ مع لبيب</button>
              </div>
            </NavLink>
          </div> */}
        </div>
        <section className="flex flex-col-reverse flex-wrap justify-between gap-10 ">
          {/* <div
            className="md:flex-[0.6] 2xl:flex-1
           bg-gradient-to-r from-[#00E1B1] to-[#0089E6] p-10 rounded-[10px]"
          >
            <div className="flex flex-col gap-5 text-xl ">
              <div className="flex justify-center w-full ">
                <p className="bg-primary-color flex justify-center w-full md:w-3/5 py-2 rounded-[10px] text-sm 2xl:text-xl">
                  اجعل فقراتك ممتعة
                </p>
              </div>
              <div className="flex flex-col gap-5">
                <div className=" bg-primary-color flex  rounded-[20px] p-3 flex-col">
                  <p className="flex justify-end text-sm text-right 2xl:text-xl">
                    لتنقل في المشهد المعقد لتطوير الذكاء الاصطناعي المسؤول
                  </p>
                </div>
                <div className=" bg-primary-color  flex   h-24 rounded-[20px] p-5 flex-col relative">
                  <p className="flex justify-end text-sm 2xl:text-xl">
                    {textToCopy}
                  </p>
                  <button
                    onClick={handleCopyClick}
                    className="absolute bottom-0 right-0 p-5"
                  >
                    <Tooltip title={copied ? "Copied" : "Copy"}>
                      <img src={copyIcon} alt="" />
                    </Tooltip>
                  </button>
                </div>
              </div>
              <div className="flex flex-col-reverse items-center justify-between gap-5 md:flex md:flex-row">
                <button className="bg-white rounded-[10px] text-black text-sm 2xl:text-xl p-3 px-5">
                  إعادة الصياغة
                </button>
                <p className="text-sm 2xl:text-xl">
                  7 الكلمات الرئيسية الصحيحة
                </p>
              </div>
              <div className="flex flex-row-reverse gap-3 ">
                <p className=" text-sm 2xl:text-lg bg-gradient-to-r from-[#11b591] to-[#1575b5] flex justify-end border border-1 border-primary-color flex  rounded-[20px] p-5 text-right leading-7 py-5">
                  في فيلم اشتهر شاب بأدائه في مسرحية في برودواي. إنه على وشك
                  النجومية ، لكن ليس لديه أي فكرة عن كيفية الوصول إلى هناك. إنه
                  محير مما يعنيه أن تكون من المشاهير. أخيرًا ، يدرك أن لديه
                  موهبة وطموح ، لكن يجب عليه استخدام كليهما بفعالية لتحقيق هدفه.
                  الموهبة والطموح مرتبطان ارتباطًا وثيقًا ، ومن الأهمية بمكان أن
                  يفهم الناس ذلك.
                </p>
                <p className="flex items-end">
                  <Avatar
                    alt="labib"
                    src={heroLogo}
                    className="w-2 h-2 bg-primary-color"
                  />
                </p>
              </div>
            </div>
          </div> */}
          <div className="md:flex-[0.4]  2xl:flex-1  md:py-10">
            <div className="flex flex-col items-end gap-2 mt-52 md:mr-40 md:mt-52 md:gap-14 ">
              <h1
                className=" text-xl sm:text-lg md:text-xl lg:text-3xl 2xl:text-4xl w-full md:max-w-[800px] text-right text-[#00FF9E] "
                style={{ lineHeight: "50px" }}
              >
                  {sentence.split(" ").map((word, index) => (
                  <motion.span
                    key={index}
                    initial="initial"
                    animate="animate"
                    variants={wordVariants}
                    transition={{ duration: 0.9, delay: index * 0.5 }}
                  >
                    {word}{" "}
                  </motion.span>
                ))}
                {sentence2.split(" ").map((word, index) => (
                  <motion.span
                  className="text-white"
                    key={index}
                    initial="initial"
                    animate="animate"
                    variants={wordVariants2}
                    transition={{ duration: 0.9, delay: index * 0.3 }}
                  >
                    {word}{" "}
                  </motion.span>
                ))}
              </h1>
              <p
                className="text-sm sm:text-base md:text-lg lg:text-xl 2xl:text-3xl md:max-w-[700px] text-right  "
                style={{ lineHeight: "50px" }}
              >
                يمكنك مع لبيب أن تحول الأفكار الى محتوى عربي متفاعل بزمن وجهد
                وتكلفة أقل
              </p>
              <div className="mt-10 md:mt-0">
                <NavLink to="/start">
                  <div
                    className="border-1 border-[#00FF9E] rounded-[10px] sm:text-xl xl:text-2xl w-full h-full p-3 sm:h-full sm:w-full sm:py-[3%] px-20 text-center shadow-lg"
                    style={{ boxShadow: "0 0 5px 1px #00FF9E" }}
                  >
                    <h1 className="">إبدأ مع لبيب</h1>
                  </div>
                </NavLink>
              </div>

              {/* <div className="flex flex-row-reverse items-center jusitfy-center">
           
                <StyledSVG
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 337.66 532.72"
                >
                  <g id="Layer_1-2" data-name="Layer 1">
                    <AnimatedPath
                      className="hidden cls-1 lg:block"
                      d="m1,1h118.21v250.61c39.15.04,78.3.08,445.45.11"
                    />
                  </g>
                </StyledSVG>
                <img
                  src={bottomLine}
                  ref={imageRef}
                  style={{ zIndex: -10 }}
                  className=" hidden lg:block bg-[#00E1B1] 2xl:w-[810px] h-1 absolute line mt-5"
                />
              </div> */}
            </div>
          </div>
        </section>
        {/* <div className="flex justify-center md:text-4xl ">
          <h1>يمكنك استخدام لبيب لكل ماتريد </h1>
        </div> */}
      </div>
      {/* <div className=" bg-gradient-to-r from-[#00E1B1] to-[#0089E6]">
        <div className="flex items-center h-20 ">
          <marquee
            width="100%"
            direction="left"
            scrollamount="15"
            behavior="scroll"
          >
            <div className="flex justify-between gap-40 ">
              {data?.patterns?.map((item, index) => (
                <div className="flex items-center gap-4 ">
                  <p className="text-xl">{item.title}</p>
                  <img src={item.icon} alt="labib" className="w-10 h-10" />
                </div>
              ))}
            </div>
          </marquee>
        </div>
      </div> */}
      {/* <section className="my-20 md:mb-80">
        <div className="flex flex-col justify-around gap-10 md:flex md:flex-row md:mx-40">
          {paraChange.map((item, index) => (
            <div
              className={`flex flex-col gap-10 w-96 ${
                index === 1 && "translate-y-0 md:translate-y-60"
              }  ${
                index === 0 && "translate-y-0 md:translate-y-32"
              }   items-end text-right `}
            >
              <p className="flex justify-end text-2xl">نص يمكن استبداله</p>
              <div className="border border-1 border-[#006BF9] h-[0.5] w-60 "></div>
              <div className="bg-gradient-to-b from-[#515392] to-[#090D66] border border-1 border-[#00FF9E] rounded-[10px] flex flex-col justify-end  items-end p-10 pb-32 gap-10">
                <img src={item.img} alt="" className="w-8 h-8" />
                <h1 className="text-2xl">{item.title}</h1>
                <p className="text-base">{item.body} </p>
              </div>
            </div>
          ))}
        </div>
      </section> */}

      {/* email */}
      <div className="p-10 ">
        <div className="flex flex-col-reverse justify-center md:flex md:flex-row md:px-10">
          <div className="flex flex-col items-center gap-10 mt-5 sm:w-full md:w-1/2 ">
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="bg-[#181825] p-3 w-full md:w-1/1 2xl:w-1/1 rounded-[10px] placeholder:pl-5"
              placeholder="example@gmail.com"
            />
            <button
              onClick={hanldeEmail}
              className="bg-gradient-to-r from-[#00FF9E] to-[#89FFFD] w-full md:w-1/1 2xl:w-1/1 rounded-[10px] p-3 text-black "
            >
              <h1>إرسال</h1>
            </button>
          </div>
          <div className="flex flex-col items-end gap-10">
            <h1 className="text-xl text-right sm:text-lg md:text-xl lg:text-3xl 2xl:text-4xl">
              اشترك في نشرتنا الإخبارية
            </h1>
            <p className="text-[#9D9D9D] text-sm sm:text-base md:text-lg lg:text-xl 2xl:text-2xl md:w-1/2  text-right ">
              احصل على أحدث الإخطارات حول ما قمنا بتحديثه الآن وفي الشهر الماضي
              ، ويمكنك إلغاء الاشتراك في البريد الإلكتروني من الإشعارات المتعلقة
              بنا
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
