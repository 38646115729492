import { useQuery } from "react-query";
import { _Chat } from "api/chat/chat";

export const useChat = (id) => {
  const { data, isLoading, refetch } = useQuery(
    ["chat"],
    () => _Chat.index().then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
    refetch,
  };
};
