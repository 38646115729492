import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo2.png";
import { NavLink, useLocation, useParams } from "react-router-dom";

const Header = () => {
  const params = useParams();
  const location = useLocation();
  const [justifycenter, setJustifyCenter] = useState("justify-center");
  const landing = location.pathname === "/";
  const start = location.pathname === "/start";
  const chat = location.pathname === `/chat/${params.id}`;
  useEffect(() => {
    if (start || chat) {
      setJustifyCenter("justify-center");
    } else {
      setJustifyCenter("justify-start");
    }
  }, [start, chat]);

  return (
    <div className="">
      <div
        className={`flex ${justifycenter} mx-5 ${
          landing ? "hidden" : ""
        }`}
      >
        <NavLink to="/" className={`flex ${justifycenter} items-center`}>
          <img
            src={logo}
            alt="labib"
            className={`${
              start || chat
                ? "w-1/1 h-28 md:w-80 md:h-full pt-10 "
                : "md:w-80 h-1/2 sm:h-full p-2"
            }`}
          />
        </NavLink>
  
      </div>
    </div>
  );
};

export default Header;
